@import "../../static/scss/colours";
@import "../../static/scss/mixin";

.navCompact {
  flex: 0 0 90px;
  height: 90px;
  display: flex;
  border-bottom: 1px solid $border-color;
  align-items: center;
  .navHome {
    display: flex;
    border-right: 1px solid $border-color;
    &:last-of-type {
      border-right: none;
      border-left: 1px solid $border-color;
    }
    .logo {
      height: 90px;
      flex: 90;
      width: 90px;
      padding: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img,
      svg {
        transition: 300ms all ease;
      }
      .label {
        display: none;
        position: absolute;
        z-index: 1;
        top: 70px;
        height: 60px;
        background: white;
        border: 1px solid $border-color;
        padding: 0 20px;
        align-items: center;
        white-space: nowrap;
        &.left {
          left: 25%;
        }
        &.right {
          right: 25%;
        }
      }
      &.sm {
        padding: 24px;
      }
      &:hover {
        img,
        svg {
          transform: scale(1.1);
        }
        .label {
          display: flex;
        }
      }
    }
  }
  .area {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .society {
      height: 55px;
      width: auto;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");

@import "mixin";
@import "colours";

@import "homePage";
@import "inviteModal";
@import "modalSoon";

@import "getMobileApp";
@import "learnMoreMobile";

body {
  font-family: Montserrat;
  height: 100vh;
  margin: 0;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  #root {
    display: flex;
    flex-direction: row;
    height: 0;
    flex: 1;
  }
}
* {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
input {
  &::placeholder {
    font-family: Montserrat !important;
  }
}
a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
::-webkit-scrollbar-track {
  background: #00000000;
}
::-webkit-scrollbar-thumb {
  background: rgba(85, 85, 85, 0.055);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(51, 51, 51, 0.2);
}

.modalSoon {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: flex;
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.85);
  }
  .comingSoon {
    width: 85%;
    height: auto;
    max-width: 550px;
    margin: auto;
    z-index: 1;
  }
}

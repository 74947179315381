.learnMoreMobile {
  flex: 1;
  display: flex;
  flex-direction: column;
  .navbar {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $border-color;
    .logo {
      height: 50%;
      width: auto;
      max-width: 70%;
      object-fit: contain;
    }
  }
  .bgWrap {
    width: 100%;
    position: relative;
    overflow: hidden;
    .bgHid {
      width: 100%;
      height: auto;
      opacity: 0;
    }
    .carouselWrap {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      perspective: 1000px;
      transform-style: preserve-3d;
      .content {
        width: 80%;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        margin: auto;
        transition: all 400ms ease;
        &.prev {
          transform: translate3d(-50%, 0, -300px);
        }

        &.next {
          transform: translate3d(50%, 0, -300px);
        }

        &.active {
          transform: translate3d(0, 0, 0);
        }
        &.inactive {
          opacity: 0;
          transform: translate3d(-2px, 0, -500px);
        }
      }
    }
  }
  .titleOne {
    color: $primary-color;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
  }
  .titleTwo {
    color: $app-color;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  .desc {
    margin: 0 auto;
    width: 85%;
    line-height: 2;
    font-size: 2vh;
  }
  .btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    .btnIdea,
    .btnTalk {
      height: 60px;
      width: 80%;
      cursor: pointer;
      min-width: 85%;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
      font-size: 19px;
      font-weight: 600;
      position: relative;
      overflow: hidden;
      border: 1px solid $border-color;
      white-space: nowrap;
      padding: 0 20px;
      img {
        height: 40%;
        width: auto;
        margin-right: 6px;
      }
      &::before {
        content: "";
        background-color: $primary-color;
        width: 100%;
        height: 60px;
        position: absolute;
        left: -100%;
        transform: rotateZ(45deg);
      }
      &:hover {
        &::before {
          transition: all 0.4s ease;
          left: 100%;
        }
      }
    }
    .btnIdea {
      background: white;
      color: $primary-color;
      margin-bottom: 20px;
    }
    .btnTalk {
      background: white;
      color: $app-color;
    }
  }
}
